import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {User} from '../modules/user/classes/user';
import {DataService} from '../services/data.service';

@Injectable()
export class AuthenticatedUserGuard implements CanActivate {
    /**
     * Creates an instance of resolveGuard.
     *
     * @param  auth the authentication service
     * @param  router the angular router
     *
     * @memberOf AuthenticatedUserGuard
     */
    constructor(public auth: AuthenticationService, public router: Router, private dataService: DataService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const self = this;
        const mf = (user: User) => {
            if (!user || user.resettingPassword) {
                self.auth.ssoLogin();
                return false;
            }
            return !!user;
        };

        return this.auth.getAuthenticated().pipe(map(mf));
    }
}
